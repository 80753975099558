import React, { useEffect, useRef } from 'react';
import {
  Button, Container, Row, Col,
} from 'reactstrap';

import Layout from '../components/Layout';
import SEO from '../components/seo';

import IntroBackground from '../assets/images/intro.png';
import FeatureConnectionImg from '../assets/images/Feature1_connection.svg';
import FeatureSwipeImg from '../assets/images/Feature2_swipe.svg';
import FeatureConversationImg from '../assets/images/Feature3_conversation.svg';
import FeatureDateImg from '../assets/images/Feature4_date.svg';
import DatingImg from '../assets/images/Dating_image.png';
import AppScreenL from '../assets/images/App Screen_L.png';
import AppScreenM from '../assets/images/App Screen_Middle.png';
import AppScreenR from '../assets/images/App Screen_R.png';
import StaffGrid from '../components/StaffGrid';

const featureImgStyle = {
  background: 'white', height: '150px', width: '210px', padding: '1.5rem',
};
const featureHeaderStyle = { margin: '2rem 0 0.5rem 0' };
const featureDescStyle = { margin: '0 3rem 2rem 0' };

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const IndexPage = () => {
  const featuresRef = useRef();
  const screensRef = useRef();
  const teamRef = useRef();

  // manually scroll to anchor in href on page load
  useEffect(() => {
    const anchorMatch = window.location.href.match(/.*\#(\w+)/);

    if (anchorMatch && anchorMatch.length > 1) {
      const anchor = anchorMatch[1];

      switch(anchor) {
        case "features":
          scrollToRef(featuresRef);
          break;
        case "screens":
          scrollToRef(screensRef);
          break;
        case "team":
          scrollToRef(teamRef);
          break;
        default:
          break;
      }
    }
  }, []);
  
  return (
    <Layout>
      <SEO title="Home" />
      <section
        id="intro"
        className="full-width"
        style={{
          backgroundImage: `url(${IntroBackground})`,
          minHeight: '591px',
        }}
      >
        <Container>
          <h1 className="text--title" style={{ color: 'white' }}>Make your heart flutter</h1>
          <p style={{ color: '#d6d6d6' }}>
            Remember when you got butterflies before your first date?
            <br />
            Set your heart aflutter and make dating fun again with Blindly.
          </p>
          <Button>Sign Up</Button>
        </Container>
      </section>
      <section id="features" ref={featuresRef} className="full-width bg-primary">
        <h3 className="text-color--primary section-header" style={{ textAlign: 'center' }}>Blindly Features</h3>
        <Container>
          <Row className="justify-md-center">
            <Col xs={6} md={3}>
              <img
                src={FeatureConnectionImg}
                alt="connection"
                style={featureImgStyle}
              />
              <h4 className="text-color--primary" style={featureHeaderStyle}>Meaningful Connections</h4>
              <p className="text-color--secondary" style={featureDescStyle}>Be more than just a face in the crowd</p>
            </Col>
            <Col xs={6} md={3}>
              <img
                src={FeatureSwipeImg}
                alt="swipe"
                style={featureImgStyle}
              />
              <h4 className="text-color--primary" style={featureHeaderStyle}>Skip the Swipe</h4>
              <p className="text-color--secondary" style={featureDescStyle}>We&apos;ll pick a daily batch of matches for you</p>
            </Col>
            <Col xs={6} md={3}>
              <img
                src={FeatureConversationImg}
                alt="conversation"
                style={featureImgStyle}
              />
              <h4 className="text-color--primary" style={featureHeaderStyle}>Be Choosy</h4>
              <p className="text-color--secondary" style={featureDescStyle}>Chat only with people you actually want to talk to</p>
            </Col>
            <Col xs={6} md={3}>
              <img
                src={FeatureDateImg}
                alt="date"
                style={featureImgStyle}
              />
              <h4 className="text-color--primary" style={featureHeaderStyle}>Safety First</h4>
              <p className="text-color--secondary" style={featureDescStyle}>Prioritize safety by sharing your location with friends and family</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="screens" ref={screensRef}>
        <h3 className="text-color--primary section-header" style={{ textAlign: 'center' }}>App Screens</h3>
        <Container>
          <Row>
            <Col xs={12} md={4} style={{textAlign: 'center'}}>
              <img src={AppScreenL} alt="app screen left" />
            </Col>
            <Col xs={12} md={4} style={{textAlign: 'center'}}>
              <img src={AppScreenM} alt="app screen middle" />
            </Col>
            <Col xs={12} md={4} style={{textAlign: 'center'}}>
              <img src={AppScreenR} alt="app screen right" />
            </Col>
          </Row>
        </Container>
      </section>
      <section id="secondary-info" className="full-width bg-secondary" style={{ padding: 0 }}>
          <Container style={{marginLeft: '0'}}>
            <Row>
              <Col xs={6} md={8} style={{ paddingLeft: '0'}}>
                <div style={{
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${DatingImg})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover'
                }} />
              </Col>
              <Col>
                <div style={{ padding: '4rem 0', margin: '0 auto'}}>
                  <h2 className="text-color--primary">The New Way to Date</h2>
                  <p className="text-color--secondary" style={{ margin: '2rem 0 3rem 0' }}>
                    Ditch the swiping for more meaningful connections with Blindly.
                    We&apos;re changing the way people connect online.
                  </p>
                  <Button>Sign Up</Button>
                </div>
              </Col>
            </Row>
          </Container>
      </section>
      <section id="team" ref={teamRef}>
        <h3 className="text-color--primary section-header" style={{ textAlign: 'center' }}>Meet the Team</h3>
        <StaffGrid />
      </section>
    </Layout>
  )
};

export default IndexPage;
