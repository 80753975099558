import React from "react"
import { Container, Row, Col } from "reactstrap"
import chunk from "lodash.chunk"

import SamZ from "../assets/images/Team Photo1_Sam.png"
import BobR from "../assets/images/Team Photo2_Bobby.png"
import AdamT from "../assets/images/Team Photo4_Adam.png"
import KevinN from "../assets/images/Team Photo6_Kevin.png"
import Zareen from "../assets/images/Team Photo28_zareen.png"
import James from "../assets/images/Team Photo35_James.png"

const staff = [
  {
    name: "Sam Ziaripour",
    position: "Founder / CEO",
    photo: SamZ,
  },
  {
    name: "Robert Rauscher",
    position: "COO",
    photo: BobR,
  },
  {
    name: "Kevin Nichols",
    position: "CTO",
    photo: KevinN,
  },
  {
    name: "Adam Tabriz",
    position: "Executive Advisor",
    photo: AdamT,
  },
  {
    name: "James Lyons",
    position: "Full Stack Developer",
    photo: James,
  },
  {
    name: "Zareen Price",
    position: "Senior Backend Developer / Team Leader",
    photo: Zareen,
  },
]

const StaffGrid = () => {
  const chunkedStaff = chunk(staff, 4)

  return (
    <Container>
      {chunkedStaff.map(row => (
        <Row key={row.map(p => p.name).toString()}>
          {row.map(person => (
            <Col xs={6} md={3} key={person.name}>
              <img src={person.photo} alt={person.name} />
              <p
                className="med center text-color--primary"
                style={{ margin: "1rem 0 0 0" }}
              >
                {person.name}
              </p>
              <p className="med text--small center text-color--secondary">
                {person.position}
              </p>
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  )
}

export default StaffGrid
